
.loader1, .loader2, .loader3 {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-animation-name: fadeIn;
    -webkit-animation-duration: 0.4s;
    animation-name: fadeIn;
    animation-duration: 0.4s;
}
.loader1 {z-index: 10000;}
.loader2 {z-index: 10001;}
.loader3 {z-index: 10002;}
.bg {
  background: #001225;
  mix-blend-mode: multiply;
  opacity: 0.6;
  width: 100%;
  height: 100vh;
}
.loading {
  border: 4px solid #ffffff00;
  border-radius: 50%;
  border-top: 4px solid #D7A048;
  border-left: 4px solid #D7A048;
  width: 100%;
  height: 100%;
  animation: spin 1s linear infinite;
}
.load {
  border: 4px solid #ffffff00;
  border-radius: 50%;
  border-top: 4px solid #009CDE;
  border-left: 4px solid #009CDE;
  width: 75%;
  height: 75%;
  animation: spin2 0.8s linear infinite;
}
.logo {
    background-image: url('lu_circle.png');
    background-size: cover;
    width: 50%;
    height: 50%;
}

  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }


  @-webkit-keyframes spin2 {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin2 {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  @-webkit-keyframes fadeIn {
    from {opacity: 0} 
    to {opacity: 1}
  }
  
  @keyframes fadeIn {
    from {opacity: 0} 
    to {opacity: 1}
  }