.cover-photo {
    height: 140px;
    width: 764px;
    left: 141px;
    top: 110px;
    border-radius: 5px;
    object-fit: cover;
    background-size: contain;
    background-repeat: no-repeat;
}

.overlapped-div {
    position: relative;
    top: 70%;
}

.video-player-container {
    width: 240px;
}