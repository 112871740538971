input:focus,
textarea:focus {
    outline: none !important;
    box-shadow: none !important;
}
.btn-check:checked + .btn-outline-primary {
    background-color: #002e5d;
    border-color: #002e5d;
    box-shadow: none;
}
.btn-check:hover + .btn-outline-primary {
    background-color: #002e5d;
    border-color: #002e5d;
}
.btn-outline-primary {
    color: #002e5d;
    border-color: #002e5d;
    text-align: left;
}
.create-job-bg {
    background: url('../../assets/img/createJob/createJobBg.png');
    background-position: center center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    overflow-y: auto;
    margin-top: -17px;
    padding-top: 60px;
    padding-bottom: 100px;
    height: 94vh;
}

.select-error {
    /* color: red!important; */
    border-color: red!important;
}

.required-tag{
    color: #009cde!important;
}

@media screen and (max-width: 720px) {
    h2 {
        font-size: 24px !important;
    }
}

.scale-out-tl {
    -webkit-animation: scale-out-tl 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53)
        both;
    animation: scale-out-tl 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@-webkit-keyframes scale-out-tl {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        opacity: 1;
    }
}
@keyframes scale-out-tl {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        opacity: 1;
    }
}

.scale-out-left {
    -webkit-animation: scale-out-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
        both;
    animation: scale-out-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@-webkit-keyframes scale-out-left {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: 0% 50%;
        transform-origin: 0% 50%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-transform-origin: 0% 50%;
        transform-origin: 0% 50%;
        opacity: 1;
    }
}
@keyframes scale-out-left {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: 0% 50%;
        transform-origin: 0% 50%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-transform-origin: 0% 50%;
        transform-origin: 0% 50%;
        opacity: 1;
    }
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
    background-color: #002e5d;
    width: 12px;
}

.ps__thumb-y {
    background-color: #002e5d;
    border-radius: 6px;
    transition: background-color 0.2s linear, width 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
    right: 2px;
    position: absolute;
    width: 12px;
}

.ps:hover > .ps__rail-y {
    opacity: 1;
}
.ps__rail-y {
    transition: background-color 0 linear, opacity 0 linear;
    -webkit-transition: background-color 0 linear, opacity 0 linear;
    opacity: 1;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
    opacity: 1;
}
