.compShowcase .react-thumbnail-generator img{
    width: 100%!important;
    height: 100%!important;
    object-fit: cover!important;
}

.empProf .react-thumbnail-generator img{
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.compShowcase .react-thumbnail-generator{
    height: 100%!important;
    object-fit: 'cover';
}

.compShowcase .react-thumbnail-generator img{
    object-fit: 'cover';
}

.react-thumbnail-generator video {
    width: 150px!important;
    height: 100px!important;
}

.react-thumbnail-generator canvas {
    display: none;
}

.profile-banner{
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}