/* LOGIN CSS */
.login-bg{
    background: url('../../assets/joblu-logos/jb-signin-bg.png');
    background-position: center center!important;
    background-size: cover!important;
    background-repeat: no-repeat!important;
    /* height: 100% !important;
    width: 100% !important; */
  }
  
  .login-card{
    background: #FFFFFF;
    border: 1px solid rgba(128, 128, 128, 0.3);
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(0, 156, 222, 0.2);
    border-radius: 5px;
  }
  .login-error {
    background: #F5ABAB;
    top: 116px;
    font-size: 14px;
    letter-spacing: 0em;
    text-align: left;
    padding: 10px;
    left: 51px;
    width: 100%;
    color: #27323C;
    opacity: 0.9;
  }


  .login-success {
    background:	hsl(143, 100%, 79%);
    top: 116px;
    font-size: 14px;
    letter-spacing: 0em;
    text-align: left;
    padding: 10px;
    left: 51px;
    width: 100%;
    color: #27323C;
    opacity: 0.9;
  }