@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');

body {
    margin: 0;
    font-family: 'Open sans';
    background: #ffffff;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #27323c;
}

.overflow-auto {
    overflow: 'auto!important';
}


fieldset{
    top: 0px!important;
}

p,
a {
    font-family: 'Open Sans', sans-serif;
    color: #27323c;
    font-size: 0.95rem;
}

h1 {
    font-size: 48px !important;
}
h2 {
    font-size: 36px !important;
}
h3 {
    font-size: 30px !important;
}
h4 {
    font-size: 24px !important;
}
h5 {
    font-size: 20px !important;
}
h6 {
    font-size: 18px !important;
}
p {
    font-size: 14px;
}

@media screen and (max-width: 720px) {
    h2 {
        font-size: 24px !important;
    }
}

/* img {
    object-fit: contain;
} */

fieldset{
    top: 0!important;
}
.btn-primary {
    background: #002e5d;
    border-radius: 3px;
}
.btn-primary:disabled {
    background: #002e5d;
    border-radius: 3px;
}
.btn {
    min-width: 50px !important;
}
.hand {
    cursor: pointer;
}

.navbar-toggler-icon {
    background-image: none !important;
}

.btn-outline-primary {
    background: transparent;
    border-color: #002e5d;
    color: #002e5d;
}

.bg-primary {
    background-color: #002e5d !important;
}
.btn-primary:hover {
    background: #00356b;
}
.btn-primary:active {
    background-color: #002e5d !important;
}

.bg-primary:hover {
    background-color: #00254a !important;
}

.text-primary {
    color: #002e5d !important;
}

.btn-outline-primary:hover {
    background-color: #002e5d;
    color: #ffffff!important;
}

.btn-outline-primary.nh:hover {
    background-color: transparent;
    color: #002e5d!important;
    border-color: #002e5d!important;
}

.nav-pills .nav-link.active {
    color: #ffffff;
    background-color: #002e5d;
}

.nav-pills .nav-link {
    color: #002e5d;
}

.br5 {
    border-radius: 5px;
}

/* END OF LOGIN CSS */

.w-136 {
  min-width: auto!important;
}

.archive-tab.nav-tabs{
    border-bottom: none!important;
}

.btn-136 {width: 100%!important}
@media screen and (min-width: 720px) {
    .w-136 {
      min-width: 136px!important;
    }
}
/* HEADER CSS */
.joblu-header {
    background: #27323c;
    color: #ffffff;
    height: 80px;
}

.joblu-header li a {
    line-height: 21px;
    color: #ffffff;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 400, 'regular';
}

.joblu-header a:hover {
    color: #f2f2f2;
}

.open-sans-semi-bold {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
}

.open-sans-body {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
}
.open-sans{
    font-family: 'Open Sans', 'sans-serif';
}
.time-ago {
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}
.h6-monserrat {
    font-family: 'Montserrat';
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

.rotate-180 {
    transform: rotate(180deg);
}
.poppins {
    font-family: 'Poppins';
}

.montserrat {
    font-family: 'Montserrat';
}

.semi-bold-600 {
    font-weight: 600;
}

.primary {
    color: #003d59;
}

.open-sans {
    font-family: 'Open Sans';
}

.medium-500 {
    font-weight: 500;
}
.swipe-comtainer {
    display: grid;
    grid-template: max-content;
}

.swipe-card {
    grid-area: 1 / 1 / 2 / 2;
}
.fs-12 {
    font-size: 12px;
}

.helper {
    --reactour-accent: #5cb7b7;
    line-height: 1.3;
    color: #2d2323;
}

.hw-24 {
    width: 24px;
    height: 24px;
}

.btn:focus,
.btn:active,
.form-select:focus {
    outline: none !important;
    box-shadow: none;
}

.notextselect {
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
  supported by Chrome, Edge, Opera and Firefox */
}
/* END OF HEADER CSS */

/*Navigation Bar*/
.nav-box-shadow {
    box-shadow: 0px 0px 10px rgba(18, 152, 159, 0.2);
}
.nav-item img {
    height: 22px;
}
.nav-item-active a:hover,
.nav-item-active a:active {
    color: #002e5d;
}

.notif-dropdown {
    width: 320px;
}
.notif-dropdown a {
    white-space: normal;
}

.navbar-nav .nav-item .nav-link .badge-counter {
    position: absolute;
    transform: scale(0.8);
    transform-origin: top right;
    right: 1.5rem;
    margin-top: -0.25rem;
}

.notifications-dropdown .dropdown-item {
    padding: 10px 0px;
    min-width: 350px;
    white-space: normal !important;
}

.notifications-dropdown .dropdown-item:last-child {
    border-bottom: none;
}

.notif-time {
    color: #93989d;
}

.notifications-dropdown a {
    text-decoration: none;
    display: block;
    width: 100%;
    padding-top: 2px;
    padding-left: 8px;
    word-wrap: break-word;
}

.notifications-dropdown img {
    border-radius: 100px;
    width: 45px;
    height: 45px;
    object-fit: cover;
    border: 2px solid #e74c3c;
}

.notif-unread {
    position: absolute;
    top: 0;
    right: 30px;
}

.msg-unread {
    position: absolute;
    top: 6px;
    right: 344px;
}

.nsb::-webkit-scrollbar {
    display: none;
}
.nsb {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.fs-8 {
    font-size: 8px;
}
.fs-9 {
    font-size: 9px;
}
.fs-10 {
    font-size: 10px;
}
.fs-11 {
    font-size: 11px;
}
.fs-12 {
    font-size: 12px;
}
.fs-14 {
    font-size: 14px;
}

a,
.btn.btn-link {
    color: #002e5d !important;
}
.btn.btn-primary:active {
    background-color: #002e5d !important;
}
.btn.btn-primary:focus {
    background-color: #002e5d !important;
}

.mb50 {
    margin-bottom: 50px !important;
}

.img-fit {
    object-fit: cover;
    object-position: center center;
}

.blur-content {
    filter: blur(5px);
}
/* ANIMATION */
.slide-in-right {
    -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        both;
    animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
        display: none !important;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}
@keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
        display: none !important;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

.slide-out-right {
    -webkit-animation: slide-out-right 0.5s
        cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    animation: slide-out-right 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
@-webkit-keyframes slide-out-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
        display: none !important;
    }
}
@keyframes slide-out-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
        display: none !important;
    }
}

.p-new{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}


.btn.btn-drop:hover{
    background-color: transparent!important;
    color: #6c757d!important;
}

.dropdown-menu li span, .MuiChip-label, button span, input{
    font-family: 'Open Sans', 'sans-serif';
}


.sticky-top {
    /* position: -webkit-sticky; Safari */
    /* position: sticky; */
    top: 100;
  }

  #pdf-download {
    display: none;
}

button.shadow-none {
    width: 100% !important;
    justify-content: center !important;
}