.notif-content {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
}
.nav-item {
    padding: 0%;
}

.nav-link {
    padding: 6px;
    margin-bottom: 0%;
    /* width: 80px; */
}

p {
    margin-bottom: 0;
}

.dropdown-toggle {
    color: gray;
}

.fs14 {
    font-size: 14px;
}
.badge {
    font-size: 11px;
    border: 1px solid black;
}

.currency-dropdown-container {
    width: 50px !important;
}

#sideNav {
    z-index: 1200 !important;
    width: 85%;
    position: fixed;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    /* display: none; */
}

.dropdown-item:active {
    background-color: #002e5d !important;
}

.nav-item.dropdown2:hover .dropdown-menu {
    display: block;
    margin-top: 0;
}
